import { defineStore } from 'pinia';
import type { JwtPayload } from 'jwt-decode';
import jwtDecode from 'jwt-decode';

// import type { LoginRefreshTokenPayload, LoginResult, LoginUser } from '~/api/generated/api';
import type { login_User, login_refreshTokenPayload } from '~/api/generated';
import { LoginService } from '~/api/generated';

export const useUserStore = defineStore('user', () => {
  const isAuthenticated = ref<boolean>(false);
  const user = ref<null | login_User>(null);
  const accessToken = ref<null | string>(null);
  const refreshToken = ref<null | string>(null);
  const logoutUrl = ref<null | string>(null);

  function setUser(u: login_User) {
    isAuthenticated.value = true;
    user.value = u;
  }

  function setAccessToken(token: string) {
    accessToken.value = token;
  }

  function setRefreshToken(token: string) {
    refreshToken.value = token;
  }

  function setLogoutUrl(url: string) {
    logoutUrl.value = url;
  }

  function setPasswordSet(passwordSet: boolean) {
    user.value!.passwordSet = passwordSet;
  }

  function getGroups() {
    return user.value?.groups || [];
  }

  async function getToken() {
    // check if token is expired
    const decodedAccessToken = jwtDecode<JwtPayload>(accessToken.value as string);
    const decodedRefreshToken = jwtDecode<JwtPayload>(refreshToken.value as string);

    if (decodedRefreshToken.exp && decodedRefreshToken.exp < Date.now() / 1000) {
      // console.info('refresh token expired, logout... ');
      logout();
    }

    if (
      decodedAccessToken.exp
        && decodedAccessToken.exp < Date.now() / 1000
        && refreshToken.value
        && accessToken.value !== null
    ) {
      accessToken.value = null;

      // console.info('token expired, refreshing... ');

      const token: login_refreshTokenPayload = { refresh_token: refreshToken.value as string };
      const result = await LoginService.refreshToken(token);

      if (result) {
        accessToken.value = result.access_token as string;
      }
      else {
        console.error('could not refresh token');
        logout();
      }
    }

    return accessToken.value as string;
  }

  function logout() {
    isAuthenticated.value = false;
    user.value = null;
    accessToken.value = '';
    refreshToken.value = null;
  }

  return {
    user,
    logoutUrl,
    accessToken,
    refreshToken,
    isAuthenticated,
    logout,
    setUser,
    setRefreshToken,
    setAccessToken,
    setPasswordSet,
    getGroups,
    getToken,
    setLogoutUrl,
  };
}, {
  persist: true,
});
