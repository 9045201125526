<script setup lang="ts">
import { useRouter } from 'vue-router';
import type { ent_App } from '~/api/generated';
import { useAppStore } from '~/stores/app';
import { AppService } from '~/api/generated';
import { useCachePasswordModal } from '~/utils/cachePasswordModal';

interface Props {
  app: ent_App
  editMode: boolean
  showTitle?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showTitle: true,
  editMode: false,
});

const emit = defineEmits<{
  (e: 'click'): void
}>();

const { openCachePasswordModal } = useCachePasswordModal();

const appStore = useAppStore();
const router = useRouter();

const url = ref<string>(props.app.url as string);
const app = ref<string>('Link');

// Set app name
if (props.app.appTypeId !== 0)
  app.value = props.app.edges?.app_type?.name as string;

const href = computed(() => {
  if (props.app.iframe || url.value === '#' || props.editMode || app.value === 'Guacamole')
    return '';

  return 'href';
});

const download = computed(() => {
  if (!props.app.download || props.editMode)
    return '';

  return 'download';
});

const t = '_blank';
const target = computed(() => {
  if (!props.app.external || props.editMode)
    return '';

  return 'target';
});

function getFileFromURL(url: string) {
  const parts = url.split('/');
  return parts[parts.length - 1];
}

function setIframeMode(url: string) {
  appStore.iframeMode! = true;
  appStore.iframeURL! = url;
  router.push('/extern');
}

async function click() {
  if (props.editMode) {
    emit('click');
    return;
  }

  // Guacamole
  if (app.value === 'Guacamole') {
    const json = JSON.parse(props.app.edges?.app_type?.config as string);
    try {
      url.value = await AppService.redirectGuacamole(json.Name);
    }
    catch {
      url.value = '/restricted';
    }

    if (url.value === 'No password found') {
      openCachePasswordModal();
      return;
    }

    if (!props.app.iframe) {
      if (props.app.external)
        window.open(url.value, '_blank');
      else
        window.location.href = url.value;
    }
  }

  // Virtuelles Klassenbuch
  if (app.value === 'Virtuelles Klassenbuch') {
    try {
      url.value = await AppService.redirectVirtuellesKlassenbuch();
    }
    catch {
      url.value = '/restricted';
    }

    if (!props.app.iframe) {
      if (props.app.external)
        window.open(url.value, '_blank');
      else
        window.location.href = url.value;
    }
  }

  // Virtueller Stundenplan
  if (app.value === 'Virtueller Stundenplan') {
    try {
      url.value = await AppService.redirectVirtuellerStundenplan();
    }
    catch {
      url.value = '/restricted';
    }

    if (!props.app.iframe) {
      if (props.app.external)
        window.open(url.value, '_blank');
      else
        window.location.href = url.value;
    }
  }

  if (props.app.iframe)
    setIframeMode(url.value);
}
</script>

<template>
  <a
    :[href]="url"
    :[target]="t"
    :[download]="getFileFromURL(url as string)"
    :class="(url as string) !== '#'
      ? 'cursor-pointer text-black hover:text-black hover:no-underline'
      : 'text-black hover:text-black hover:no-underline'"
    @click="click()"
  >
    <div class="h-full w-25 flex flex-col md:w-33 anim-scale-95">
      <div
        :class="`${props.app.color} rounded-full h-15 w-15 md:h-20 md:w-20 drop-shadow-lg self-center flex items-center justify-center text-center`"
      >
        <div :class="`${props.app.iconName} text-3xl ${props.app.iconColor}`" />
      </div>
      <div v-if="props.showTitle" class="line-clamp-2 pt-2 text-center" :title="props.app.title">
        {{ props.app.title }}
      </div>
    </div>
  </a>
</template>
