<script  setup lang="ts">
import { useProgrammatic } from '@oruga-ui/oruga-next';

import { computed, ref, watch } from 'vue';
import UserManagementBlockUser from './UserManagementBlockUser.vue';
import UserManagementChangePassword from './UserManagementChangePassword.vue';

import type { users_User } from '~/api/generated';
import { UserService } from '~/api/generated';

interface Props {
  title: string
  icon?: string
  hideFooter?: boolean
  size?: string
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  size: 'medium',
  icon: 'i-fa6-solid-xmark',
  hideFooter: false,
});

defineEmits<{
  (e: 'close'): void
}>();

const users = ref<Array<users_User>>([]);
const usersTable = ref<Array<users_User>>([]);
const query = ref<string>('');

const page = ref<number>(1);
const perPage = ref<number>(8);
const showMessage = ref<string>('');

const searchData = ref<users_User[] | undefined>(undefined);

const buttonEnabled = computed(() => {
  return query.value.length > 1;
});

async function searchUsers() {
  if (buttonEnabled.value)
    searchData.value = await UserService.ldapGetUsers(query.value);
}

function setPage(p: number) {
  page.value = p;
  usersTable.value = users.value.slice((page.value - 1) * perPage.value, page.value * perPage.value);
}

watch (searchData, () => {
  if (searchData.value !== undefined) {
    // copy array to avoid table re-rendering
    users.value = [...searchData.value as Array<users_User> ?? []];
    if (users.value.length === 0)
      showMessage.value = 'Keine Benutzer gefunden';
    else
      showMessage.value = '';

    setPage(1);
  }
});

const oruga = useProgrammatic();

function openBlockUserModal(user: users_User) {
  oruga.oruga.modal.open({
    component: UserManagementBlockUser,
    props: {
      user,
      icon: 'i-fa6-solid-lock',
    },
    events: {
      reload: () => { searchUsers(); },
    },

    hasModalCard: true,
    trapFocus: true,
    scroll: 'keep',
  });
}

function openChangePasswordModal(user: users_User) {
  oruga.oruga.modal.open({
    component: UserManagementChangePassword,
    props: {
      user,
      icon: 'i-fa6-solid-key',
    },
    events: {
      reload: () => { searchUsers(); },
    },

    hasModalCard: true,
    trapFocus: true,
    scroll: 'keep',
  });
}
</script>

<template>
  <BaseModal
    title="Benutzer suchen"
    :hide-footer="true"
    :size="usersTable.length > 0 ? 'large' : 'medium'"
    :icon="props.icon"
    @close="$emit('close')"
  >
    <template #body>
      <form @submit.prevent="searchUsers">
        <div class="flex gap-2">
          <o-field class="grow">
            <o-input
              v-model="query"
              placeholder="Name, Benutzername oder E-Mail Adresse"
            />
          </o-field>
          <o-button
            :disabled="!buttonEnabled"
            native-type="submit"
          >
            <div class="i-fa6-solid-magnifying-glass" />
          </o-button>
        </div>
        <div v-if="showMessage" class="text-sm">
          {{ showMessage }}
        </div>
      </form>
      <div v-if="usersTable.length > 0" class="pt-4">
        <OTable

          :data="usersTable"

          striped backend-sorting hoverable
          :mobile-cards="false"
          class="w-full overflow-x-auto"
        >
          <o-table-column field="username" label="Benutzername">
            <template #default="props">
              {{ props.row.username }}
            </template>
          </o-table-column>
          <o-table-column field="name" label="Name">
            <template #default="props">
              {{ props.row.name }}
            </template>
          </o-table-column>
          <o-table-column field="class" label="Klasse">
            <template #default="props">
              {{ props.row.class !== '' ? props.row.class : '-' }}
            </template>
          </o-table-column>
          <o-table-column field="email" label="E-Mail Adresse">
            <template #default="props">
              {{ props.row.email }}
            </template>
          </o-table-column>
          <o-table-column field="actions" label="">
            <template #default="props">
              <div class="flex justify-end gap-2">
                <o-button v-tooltip.right="'Passwort ändern'" @click="openChangePasswordModal(props.row)">
                  <div class="i-fa6-solid-key" />
                </o-button>
                <o-button
                  v-tooltip.right="props.row.blocked ? 'Benutzer freischalten' : 'Benutzer blockieren'"
                  :variant="props.row.blocked ? 'danger' : 'success'"
                  @click="openBlockUserModal(props.row)"
                >
                  <div class="i-fa6-solid-lock" />
                </o-button>
              </div>
            </template>
          </o-table-column>
        </OTable>
        <TablePagination
          v-model="page"
          :total="users.length"
          :per-page="perPage"
          @update:model-value="setPage"
        />
      </div>
    </template>
    <template #footer>
      <div
        class="flex justify-end"
      />
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>

</style>
