<script  setup lang="ts">
import type { ent_App } from '~/api/generated';

interface Props {
  bubble: ent_App
  title: string
  addBubble?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  addBubble: false,
});

const emit = defineEmits<{
  (e: 'close'): void
  (e: 'delete', result: ent_App): void
  (e: 'save', result: ent_App): void
}>();

const preview = ref<ent_App>(Object.assign({}, props.bubble));

const title = ref<string>(props.bubble.title as string);
const url = ref<string>(props.bubble.url as string);
const color = ref<string>(props.bubble.color as string ?? 'bg-light-primary');
const iconColor = ref<string>(props.bubble.iconColor as string ?? 'bg-light');
const iconName = ref<string>(props.bubble.iconName as string ?? 'i-fa6-solid-user');
const iframe = ref<boolean>(props.bubble.iframe as boolean);
const external = ref<boolean>(props.bubble.external as boolean);
const download = ref<boolean>(props.bubble.download as boolean);
const appType = ref<number>(props.bubble.appTypeId as number);

const appTypeName = ref<string>('Link');

const groups = ref<string[]>(props.bubble.groups !== '' && props.bubble.groups !== undefined
  ? (props.bubble.groups as string).split(',') as string[]
  : []);

function updatePreviewBubble() {
  preview.value.title = title.value;
  preview.value.url = url.value;
  preview.value.color = color.value;
  preview.value.iconColor = iconColor.value;
  preview.value.iconName = iconName.value;
}

function saveBubble() {
  const result = Object.assign({}, props.bubble);
  result.title = title.value;
  result.url = url.value;
  result.color = color.value;
  result.iconColor = iconColor.value;
  result.iconName = iconName.value;
  result.iframe = iframe.value;
  result.external = external.value;
  result.download = download.value;
  result.groups = groups.value.join(',');
  result.appTypeId = appType.value;

  emit('save', result);
  emit('close');
}

function deleteBubble() {
  emit('delete', props.bubble);
  emit('close');
}

function setAppType(app: string) {
  appTypeName.value = app;
}

watch (external, (value) => {
  if (value)
    iframe.value = false;
});
watch (iframe, (value) => {
  if (value)
    external.value = false;
});

watch (title, () => updatePreviewBubble());
watch (color, () => updatePreviewBubble());
watch (iconColor, () => updatePreviewBubble());
watch (iconName, () => updatePreviewBubble());
</script>

<template>
  <BaseModal
    :title="props.title"
    @submit="saveBubble()"
    @close="$emit('close')"
  >
    <template #body>
      <div class="flex flex-row gap-10">
        <div class="flex flex-col gap-10 pt-2">
          <div v-if="!addBubble" class="flex flex-col gap-2">
            <b>Vorher:</b>
            <IconBubble
              :app="props.bubble"
              :edit-mode="true"
            />
          </div>
          <div class="flex flex-col gap-2">
            <b v-if="!addBubble">Nachher:</b>
            <b v-else>Preview:</b>
            <IconBubble
              :app="preview"
              :edit-mode="true"
            />
          </div>
        </div>
        <div class="w-full">
          <o-field label="Titel">
            <o-input
              v-model="title"
              required
            />
          </o-field>
          <o-field label="Bubble Typ">
            <AppTypePicker v-model="appType" @app-type-changed="setAppType($event)" />
          </o-field>
          <o-field v-if="appType === 0" label="URL">
            <o-input
              v-model="url"
              required
            />
          </o-field>
          <o-field label="Hintergrundfarbe">
            <ColorPicker v-model="color" />
          </o-field>
          <div class="flex-cols flex justify-between gap-4">
            <o-field label="Icon">
              <IconPicker v-model="iconName" />
            </o-field>
            <o-field class="flex-grow" label="Icon Farbe">
              <ColorPicker v-model="iconColor" />
            </o-field>
          </div>
          <div class="flex flex-row flex-wrap justify-between gap-4">
            <o-field label="Zugriffsberechtigung">
              <GroupsPicker v-model="groups" />
            </o-field>
            <o-field label="Ziel">
              <div class="flex flex-col">
                <o-checkbox
                  v-model="iframe"
                >
                  Im Iframe öffnen
                </o-checkbox>
                <o-checkbox
                  v-model="external"
                >
                  In neuem Fenster öffnen
                </o-checkbox>
                <o-checkbox
                  v-model="download"
                >
                  Downloaden
                </o-checkbox>
              </div>
            </o-field>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div
        class="flex justify-between gap-4"
      >
        <o-button
          @click="$emit('close')"
        >
          Abbrechen
        </o-button>
        <div class="flex flex-row gap-4">
          <o-button
            v-if="props.title === 'Bubble bearbeiten'"
            variant="danger"
            @click="deleteBubble()"
          >
            Löschen
          </o-button>
          <o-button
            variant="success"
            native-type="submit"
          >
            Speichern
          </o-button>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>

</style>
