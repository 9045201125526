<script  setup lang="ts">
import { ref, watch } from 'vue';

import type { ApiResponse } from '~/utils/ApiResponse';
import type {
  accounts_checkCodePayload,
  accounts_generateCodePayload,
  accounts_resetPasswordPayload,
} from '~/api/generated';
import { AccountsService } from '~/api/generated';

const user = ref<string>('');
const showMessage = ref<boolean>(false);
const message = ref<string>('');
const error = ref<boolean>(false);
const resetCode = ref<string>('');
const step = ref<number>(1);
const password = ref<string>('');
const isSecure = ref<boolean>(false);

const isLoading = ref<boolean>(false);

const requestData = ref<ApiResponse<string>>({ data: undefined, status: undefined });

async function sendPasswordCode() {
  const userData: accounts_generateCodePayload = { user: user.value };
  AccountsService.passwordCreateResetCode(userData)
    .then(result => requestData.value = { data: result.username, status: 'success' })
    .catch(() => requestData.value = { data: undefined, status: 'error' });
}

async function checkPasswordCode() {
  const c: accounts_checkCodePayload = { user: user.value, code: resetCode.value };
  AccountsService.checkResetCode(c)
    .then(result => requestData.value = { data: result, status: 'success' })
    .catch(() => requestData.value = { data: undefined, status: 'error' });
}

async function setNewPassword() {
  if (isSecure.value) {
    isLoading.value = true;
    const resetPassword: accounts_resetPasswordPayload = { user: user.value, password: password.value, code: resetCode.value };
    AccountsService.resetChangePassword(resetPassword)
      .then(result => requestData.value = { data: result, status: 'success' })
      .catch(() => requestData.value = { data: undefined, status: 'error' });
  }
}

watch(requestData, () => {
  isLoading.value = false;

  /** Is successful */
  if (requestData.value.status === 'success') {
    error.value = false;
    showMessage.value = false;
    message.value = '';

    step.value++;

    if (step.value > 3) {
      showMessage.value = true;
      message.value = 'Das Passwort wurde erfolgreich geändert.';
    }
  }
  /** Has failed */
  else if (requestData.value.status === 'error') {
    if (step.value === 1) {
      step.value++;
    }
    else if (step.value === 2) {
      showMessage.value = true;
      error.value = true;
      message.value = 'Der eingegebene Code ist ungültig.';
    }
    else if (step.value >= 3) {
      showMessage.value = true;
      error.value = true;
      message.value = 'Das Passwort konnte nicht geändert werden.';
    }
  }
});
</script>

<template>
  <BaseModal
    title="Passwort vergessen?"
    @close="$emit('close')"
  >
    <template #body>
      <template v-if="isLoading">
        <div class="flex justify-center">
          <div class="flex items-center justify-center">
            <div i="fa6-solid-spinner" class="m-4 h-8 w-8 animate-spin" />
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="(!showMessage && step === 1)">
          <o-input
            v-model="user"
            name="user"
            placeholder="Benutzername oder E-Mail-Adresse"
            type="text"
            icon="fa6-solid-user"
          />
        </div>
        <div v-if="(!showMessage && step === 2)">
          Falls Sie eine E-Mail-Adresse in Ihrem Profil hinterlegt haben, erhalten Sie in Kürze eine E-Mail mit einem achtstelligen Code.<br><br>
          Um fortzufahren geben Sie bitte diesen Code ein.
          <CodeInput v-model="resetCode" class="mt-4" />
        </div>
        <div v-if="(!showMessage && step >= 3)">
          <div class="flex flex-col gap-4">
            <o-input
              v-model="password"
              password-reveal
              type="password"
              placeholder="Neues Passwort"
              icon="fa6-solid-key"
            />
            <PasswordCheck v-model="isSecure" :password="password" />
          </div>
        </div>
        <div v-if="showMessage">
          {{ message }}
        </div>
      </template>
    </template>
    <template #footer>
      <template v-if="!isLoading">
        <div
          v-if="(!showMessage && step === 1)"
          class="flex justify-end"
        >
          <button
            class="btn btn-success"
            @click="sendPasswordCode()"
          >
            Weiter
          </button>
        </div>
        <div
          v-if="!showMessage && step === 2"
          class="flex justify-end"
        >
          <button
            class="btn btn-success"
            @click="checkPasswordCode()"
          >
            Weiter
          </button>
        </div>
        <div
          v-if="!showMessage && step >= 3"
          class="flex justify-end"
        >
          <button
            class="btn btn-success"
            :disabled="!isSecure"
            @click="setNewPassword()"
          >
            Passwort zurücksetzen
          </button>
        </div>
        <div v-if="showMessage" class="flex justify-end">
          <button
            v-if="!error"
            class="btn btn-success"
            @click="$emit('close')"
          >
            Okay
          </button>
          <button
            v-else
            class="btn"
            @click="showMessage = false;"
          >
            Zurück
          </button>
        </div>
      </template>
    </template>
  </BaseModal>
</template>

<style lang="scss" scoped>

</style>
