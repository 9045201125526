<script setup lang="ts">
import { useAppStore } from '~/stores/app';
import { useUserStore } from '~/stores/user';

const app = useAppStore();
const user = useUserStore();
const router = useRouter();

window.onpopstate = function () {
  app.stopIframeMode();
};
</script>

<template>
  <div :class="`flex flex-row h-10 justify-between gap-8 ${app.iframeMode ? 'border-b-1 border-light-primary' : ''}` ">
    <img src="/images/logo.png" alt="Logo" :class="`cursor-pointer ${app.iframeMode ? 'h-8 m-1' : 'h-10'}`" @click="app.stopIframeMode()">
    <a v-if="!user.isAuthenticated" @click="router.push('/login')">
      <o-button class="px-2">
        <div class="flex flex-row gap-2">
          <div class="i-fa6-solid-right-to-bracket self-center" />
          Login
        </div>
      </o-button>
    </a>
    <div v-if="app.adminMode" class="self-center text-lg sm:text-2xl">
      Editiermodus
    </div>
    <div v-if="user.isAuthenticated" class="flex gap-5">
      <o-button v-if="app.iframeMode" variant="success" class="m-1 text-sm" @click="app.stopIframeMode()">
        Zurück zum Portal
      </o-button>

      <PortalMenu :size="app.iframeMode ? 'small' : 'normal'" />
    </div>
  </div>
</template>
