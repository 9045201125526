import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', () => {
  const router = useRouter();

  const adminMode = ref<boolean>(false);
  const iframeMode = ref<boolean>(false);
  const iframeURL = ref<string>('');

  function toggleAdmin() {
    adminMode.value = !adminMode.value;
  }

  function stopIframeMode(redirect = '/') {
    iframeMode.value = false;
    adminMode.value = false;
    iframeURL.value = '';
    router.push(redirect);
  }
  return {
    stopIframeMode,
    toggleAdmin,
    adminMode,
    iframeMode,
    iframeURL,
  };
});
