<script setup lang="ts">
// import { toggleDark } from '~/composables';
import { useProgrammatic } from '@oruga-ui/oruga-next';
import Impressum from './modals/Impressum.vue';
import AccountResetWindow from './modals/AccountResetWindow.vue';
import { useUserStore } from '~/stores/user';

const user = useUserStore();
const oruga = useProgrammatic();

function openImpressumModal() {
  oruga.oruga.modal.open({
    props: {
      title: 'Impressum',
      icon: 'i-fa6-solid-book',
      size: 'large',
    },
    component: Impressum,
    hasModalCard: true,
    trapFocus: true,
    scroll: 'keep',

  });
}

function openPasswordResetModal() {
  oruga.oruga.modal.open({
    component: AccountResetWindow,
    hasModalCard: true,
    trapFocus: true,
    scroll: 'keep',
    animation: 'fade',
  });
}
</script>

<template>
  <div class="w-full flex justify-between">
    <div class="">
      <nav mt-6 text-xl>
        <!--
    <button class="icon-btn mx-2 !outline-none" title="Toggle dark mode" @click="toggleDark()">
      <div i="carbon-sun dark:carbon-moon" />
    </button> -->
      </nav>
    </div>
    <div class="f-full justify-end text-right">
      <a
        v-if="!user.isAuthenticated"
        class="cursor-pointer pr-4 text-sm text-gray-500 hover:text-gray-600 hover:underline"
        @click="openPasswordResetModal"
      >
        Passwort vergessen
      </a>

      <span
        class="cursor-pointer text-sm text-gray-500 hover:text-gray-600 hover:underline"
        @click="openImpressumModal"
      >
        Impressum & Datenschutz
      </span>
    </div>
  </div>
</template>
